<template>
	<div class="IntegralRule">
		<w-navTab titleText="积分规则"></w-navTab>
		<div class="container">
			<div class="head-nav">
				积分规则
			</div>
			<div class="recruit-item">
				<div class="recruit-title">
					<div class="recruit-title-left">
						?
					</div>
					<div>Q如何获取积分？</div>
				</div>
				<div class="recruit-text">
					通过完成常态化护苗、日常巡逻、禁毒反诈宣传、矛盾调解、心理服务、救援救护等任务，服务时长1小时可以换取30个爱心值。特殊专项任务积分设定由上级主管部门批准。
				</div>
			</div>
			<div class="recruit-item">
				<div class="recruit-title">
					<div class="recruit-title-left">
						?
					</div>
					<div>Q如何使用积分？</div>
				</div>
				<div class="recruit-text">
					<p>积分可以发布义警商圈广告。</p>
					<p>积分可以线下兑换协会商超里面的商品。</p>
					<p>积分可以购买义警商城商品，可以进行积分现金抵扣。</p>
					<br>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "IntegralRule",
		data() {
			return {

			}
		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
	.IntegralRule {
		background-color: #fff;

		.container {
			padding: 20px;
			box-sizing: border-box;

			.head-nav {
				font-size: 18px;
				line-height: 26px;
				text-align: justify;
				color: #222;
				font-weight: bold;
				margin-bottom: 20px;
			}

			.recruit-item {
				.recruit-title {
					font-size: 18px;
					line-height: 34px;
					color: #222;
					display: flex;
					justify-content: flex-start;
					align-items: center;

					.recruit-title-left {
						width: 18px;
						height: 18px;
						border-radius: 50%;
						background-color: #3377ff;
						color: #fff;
						font-size: 16px;
						text-align: center;
						line-height: 20px;
						margin-right: 5px;
					}
				}

				.recruit-text {
					font-size: 16px;
					line-height: 26px;
					color: #777;
					margin-bottom: 15px;

					p {
						margin: 0;
						padding: 0;
					}
				}
			}
		}
	}
</style>
